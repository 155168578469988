import React, { useState, useEffect, useRef, useCallback } from 'react';
import ProductCard from '../../components/ProductCard';
import Navbar from '../../components/Navbar';
import TopHeaderWithSearchBar from '../../components/TopHeaderWithSearchBar';
import StoreSectionInformation from '../../components/StoreSectionInformation';
import { MdAutorenew } from 'react-icons/md';
import SortDropdown from '../../components/SortDropdown';

interface Product {
  id: number;
  title: string;
  handle: string;
  producturl: string;
  product_type: string;
  imagesrc: string;
  price: string;
  compare_at_price: string;
  sizes: string;
}

const pageSize = 40;

const StoreTrucks: React.FC = () => {
  const [productTypes] = useState(['Trucks']);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMoreProducts, setHasMoreProducts] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const observer = useRef<IntersectionObserver | null>(null);
  const [sortOrder, setSortOrder] = useState('default');

  const fetchData = useCallback(async () => {
    setLoading(true);
    const productTypeParams = productTypes.map(type => `productType[]=${encodeURIComponent(type)}`).join('&');
    const url = `https://aqueous-tor-27753-c27a51e0a2f3.herokuapp.com/products/?format=json&page=${currentPage}&perPage=${pageSize}&${productTypeParams}&sort=${sortOrder}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setProducts(prevProducts => currentPage === 1 ? data.results : [...prevProducts, ...data.results]);
      setHasMoreProducts(data.results.length === pageSize);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [productTypes, currentPage, sortOrder]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(1);
    setProducts([]);
  }, [sortOrder]);
  
  useEffect(() => {
    if (loading || !hasMoreProducts) return;
    if (observer.current) observer.current.disconnect();
    const callback = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    observer.current = new IntersectionObserver(callback);
    const target = document.querySelector('#infinite-scroll-trigger');
    if (target) observer.current.observe(target);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMoreProducts]);

  useEffect(() => {
    setCurrentPage(1);
    setProducts([]);
  }, []);

  const fetchProductCount = useCallback(async () => {
    setLoading(true);
    try {
      const productTypeParams = productTypes.map(type => `productType[]=${encodeURIComponent(type)}`).join('&');
      const url = `https://aqueous-tor-27753-c27a51e0a2f3.herokuapp.com/products/count?${productTypeParams}`;
      const response = await fetch(url);
      const data = await response.json();
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error('Error fetching product count:', error);
      return 0; 
    } finally {
      setLoading(false);
    }
  }, [productTypes]);
  
  useEffect(() => {
    fetchProductCount();
  }, [fetchProductCount]);

  return (
    <div>
      <TopHeaderWithSearchBar basePath='trucks' />
      <Navbar />
      <StoreSectionInformation 
        heading="Trucks"
        description="Too many crooks? Shop sale trucks and save yourself some MONEY."
        resultCount={totalCount}
      />
      <SortDropdown onSortChange={(sort) => setSortOrder(sort)} />
      <main className="my-10">
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
            {loading && (
              <div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 flex justify-center items-center">
                <MdAutorenew className="animate-spin mr-2" />
                <p>Loading...</p>
              </div>
            )}
          </div>
        </div>
        <div id="infinite-scroll-trigger" style={{ height: '20px' }}></div>
      </main>
    </div>
  );
};

export default StoreTrucks;