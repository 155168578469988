import React from 'react';
import TopHeader from '../components/TopHeader';

const Landing: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-200">
      <TopHeader />
      <div className="text-center p-12">
        <h1 className="text-3xl font-bold mb-3">Welcome to Fakie Smith.</h1>
        <p className="text-xl mb-5">Shop the skate sales</p>
        <button 
          className="relative inline-block text-lg font-bold py-2 px-4 rounded overflow-hidden border-2 border-purple-500 group"
          onClick={() => window.location.href = '/store'}>
          <span className="absolute top-0 left-0 w-full h-0 transition-all bg-purple-500 group-hover:h-full ease-out duration-300"></span>
          <span className="relative text-purple-500 group-hover:text-white">Go to store</span>
        </button>
      </div>
    </div>
  );
};

export default Landing;
