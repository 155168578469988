import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import TopHeaderWithSearchBar from '../../components/TopHeaderWithSearchBar';
import Navbar from '../../components/Navbar';
import ProductCard from '../../components/ProductCard';
import SearchInformation from '../../components/SearchInformation';
import { MdAutorenew } from 'react-icons/md';
import SortDropdown from '../../components/SortDropdown';


interface Product {
    id: number;
    title: string;
    handle: string;
    producturl: string;
    product_type: string;
    imagesrc: string;
    price: string;
    compare_at_price: string;
    sizes: string;
  }

const pageSize = 40;

const StoreDecksSearch: React.FC = () => {
  let [searchParams] = useSearchParams();
  let searchTerm = searchParams.get('q') || 'All';
  const [loading, setLoading] = useState(false);
  const [productTypes] = useState(['Decks', 'Deck', 'Skateboard Deck', 'Skateboard Decks']);
  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMoreProducts, setHasMoreProducts] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const observer = useRef<IntersectionObserver | null>(null);
  const [sortOrder, setSortOrder] = useState('default');

  const fetchData = useCallback(async () => {
    setLoading(true);
    const productTypeParams = productTypes.map(type => `productType[]=${encodeURIComponent(type)}`).join('&');
    const searchQueryParam = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';
    const url = `https://aqueous-tor-27753-c27a51e0a2f3.herokuapp.com/products/?format=json&page=${currentPage}&perPage=${pageSize}&${productTypeParams}${searchQueryParam}&sort=${sortOrder}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      if (Array.isArray(data.results)) { 
        setProducts(prevProducts => currentPage === 1 ? data.results : [...prevProducts, ...data.results]);
        setHasMoreProducts(data.results.length === pageSize);
      } else {
        throw new Error('Data results is not iterable');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, sortOrder, productTypes]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (loading || !hasMoreProducts) return;
    if (observer.current) observer.current.disconnect();
    const callback = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    observer.current = new IntersectionObserver(callback);
    const target = document.querySelector('#infinite-scroll-trigger');
    if (target) observer.current.observe(target);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loading, hasMoreProducts]);

  useEffect(() => {
    setCurrentPage(1);
    setProducts([]);
  }, [searchTerm, sortOrder]);
  
  const fetchProductCount = useCallback(async () => {
    setLoading(true);
    try {
      const productTypeParams = productTypes.map(type => `productType[]=${encodeURIComponent(type)}`).join('&');
      const searchQueryParam = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';
      const url = `https://aqueous-tor-27753-c27a51e0a2f3.herokuapp.com/products/count?&${productTypeParams}&${searchQueryParam}`;
      const response = await fetch(url);
      const data = await response.json();
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error('Error fetching product count:', error);
      return 0; 
    } finally {
      setLoading(false);
    }
  }, [productTypes, searchTerm]);
  
  useEffect(() => {
    fetchProductCount();
  }, [fetchProductCount]);


  return (
    <div>
    <TopHeaderWithSearchBar basePath="decks" />
    <Navbar />
    <SearchInformation search={searchTerm} resultCount={totalCount} />
    <SortDropdown onSortChange={(sort) => setSortOrder(sort)} />
    <main className="my-10">
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
            {loading && (
              <div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 flex justify-center items-center">
                <MdAutorenew className="animate-spin mr-2" />
                <p>Loading...</p>
              </div>
            )}
          </div>
        </div>
        <div id="infinite-scroll-trigger" style={{ height: '20px' }}></div>
    </main>
  </div>
  );
};

export default StoreDecksSearch;
