import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';
import { SearchProvider } from './contexts/SearchContext';
import ReactGA from "react-ga"

const TRACKING_ID = "G-YZZBYD9954"; 
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <SearchProvider>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </SearchProvider>
  </React.StrictMode>
);

reportWebVitals();
