import React, { CSSProperties } from 'react';
import FSfakiesmithicon from '../assets/images/FSfakiesmithicon.svg';

export interface StylesDictionary{
    [Key: string]: CSSProperties;
}


const TopHeader: React.FC = () => {
    return (
        <div className="w-full h-20 flex items-center justify-center fixed top-0 z-10 bg-white px-4 shadow">
            <div className="flex justify-center items-center flex-1">
                <a href="https://fakiesmith.com" target="_blank" rel="noopener noreferrer">
                    <img 
                        src={FSfakiesmithicon} 
                        alt="Fakie Smith icon" 
                        className="h-24 w-24 object-contain" 
                    />
                </a>
            </div>
        </div>
    );
};

export default TopHeader;
