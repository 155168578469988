import React, { useState } from 'react';

interface Product {
  id: number;
  title: string;
  handle: string;
  producturl: string;
  product_type: string;
  imagesrc: string;
  price: string;
  compare_at_price: string;
  sizes: string;
}

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const [showSizes, setShowSizes] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="product-card max-w-lg bg-white rounded-lg overflow-hidden border-none m-2">
      <div 
        className="relative group"
        onMouseEnter={() => setShowSizes(true)}
        onMouseLeave={() => setShowSizes(false)}
        style={{ height: '300px' }}
      >
        <a href={product.producturl} target='_blank' rel="noreferrer">
          <div className="w-full h-full flex justify-center items-center overflow-hidden rounded-lg">
            <img className="max-w-full max-h-full" src={product.imagesrc} alt={product.title} />
          </div>
        </a>
        {showSizes && product.sizes && (
          <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white p-2 text-center rounded-b-lg">
            <p className="text-sm">Sizes: {product.sizes}</p>
          </div>
        )}
      </div>
      <div className="p-5 pt-2 pb-2">
      <div
      className="group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a href={product.producturl} target="_blank" rel="noreferrer">
        <h2 className={`text-lg font-semibold tracking-tight ${isHovered ? 'text-blue-300' : 'text-gray-900'}`}>
          {product.title}
        </h2>
      </a>
    </div>
        <div className="flex items-center">
          <p className="text-red-500 font-semibold">£{product.price}</p>
          {product.compare_at_price && <p className="text-gray-500 line-through ml-2">£{product.compare_at_price}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;




