import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const Navbar: React.FC = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const updateMedia = () => {
        setIsWideScreen(window.innerWidth > 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    return (
        <div>
            {isWideScreen ? (
                <div className="flex justify-evenly items-center bg-gray-300 py-2 w-full fixed top-20 z-10">
                    <Link to="/store/all" className="nav-link text-white transition-all duration-300 hover:underline">All</Link>
                    <Link to="/store/decks" className="nav-link text-white transition-all duration-300 hover:underline">Decks</Link>
                    <Link to="/store/wheels" className="nav-link text-white transition-all duration-300 hover:underline">Wheels</Link>
                    <Link to="/store/trucks" className="nav-link text-white transition-all duration-300 hover:underline">Trucks</Link>
                    <Link to="/store/clothes" className="nav-link text-white transition-all duration-300 hover:underline">Clothes</Link>
                    <Link to="/store/shoes" className="nav-link text-white transition-all duration-300 hover:underline">Shoes</Link>
                    <Link to="/store/accessories" className="nav-link text-white transition-all duration-300 hover:underline">Accessories</Link>
                    <Link to="/store/other" className="nav-link text-white transition-all duration-300 hover:underline">Other</Link>
                </div>
            ) : (
                <div className="bg-gray-300 py-2 w-full fixed top-20 z-10">
                    <div className="flex justify-end items-center px-4">
                        <button onClick={toggleDropdown} className="text-white">
                            {isDropdownOpen ? <MdClose size={24} /> : <FaBars size={24} />}
                        </button>
                    </div>
                    {isDropdownOpen && (
                        <div className="flex flex-col items-start px-4">
                            <Link to="/store/all" className="nav-link text-white transition-all duration-300 hover:underline py-1">All</Link>
                            <Link to="/store/decks" className="nav-link text-white transition-all duration-300 hover:underline py-1">Decks</Link>
                            <Link to="/store/wheels" className="nav-link text-white transition-all duration-300 hover:underline py-1">Wheels</Link>
                            <Link to="/store/trucks" className="nav-link text-white transition-all duration-300 hover:underline py-1">Trucks</Link>
                            <Link to="/store/clothes" className="nav-link text-white transition-all duration-300 hover:underline py-1">Clothes</Link>
                            <Link to="/store/shoes" className="nav-link text-white transition-all duration-300 hover:underline py-1">Shoes</Link>
                            <Link to="/store/accessories" className="nav-link text-white transition-all duration-300 hover:underline py-1">Accessories</Link>
                            <Link to="/store/other" className="nav-link text-white transition-all duration-300 hover:underline py-1">Other</Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Navbar;
