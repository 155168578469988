import React from 'react';

interface StoreSectionInformationProps {
  heading: string;
  description: string;
  resultCount: number;
}

const StoreSectionInformation: React.FC<StoreSectionInformationProps> = ({ heading, description, resultCount }) => {
    return (
        <div className="w-full p-4 bg-gray-200 mt-[9rem]">
            <h2 className="text-xl font-bold">{heading}</h2>
            <p>{description}</p>
            <p>{resultCount} results</p>
        </div>
    );
};

export default StoreSectionInformation;

