import React, { ChangeEvent, useState } from 'react';

interface SortDropdownProps {
  onSortChange: (value: string) => void;
}

const SortDropdown: React.FC<SortDropdownProps> = ({ onSortChange }) => {
  const [selectedValue, setSelectedValue] = useState<string>('default');

  const handleSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onSortChange(value);
    setSelectedValue(value); 

    e.target.blur();
  };

  return (
    <div className="mt-4 ml-4">
      <label htmlFor="sort-select" className="block text-gray-700 text-sm font-bold mb-2">
        Sort by
      </label>
      <select
        id="sort-select"
        value={selectedValue}
        onChange={handleSortChange}
        className="sort-select bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-3" // Adjusted classes for bigger size and full width
      >
        <option value="default">Default</option>
        <option value="price_asc">Price, low to high</option>
        <option value="price_desc">Price, high to low</option>
        <option value="a-z">Alphabetically, A-Z</option>
        <option value="z-a">Alphabetically, Z-A</option>
      </select>
    </div>
  );
};

export default SortDropdown;

