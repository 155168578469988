import React from 'react';
import TopHeader from '../components/TopHeader';

const NotFound: React.FC = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center">
      <TopHeader />
      <h1 className="text-xl md:text-3xl font-semibold">404: Page Not Found</h1>
      <p className="mt-4">Oops! The page you're looking for doesn't exist.</p>
      <button 
        className="mt-6 bg-purple-600 text-white font-semibold py-2 px-4 rounded hover:bg-purple-700 transition duration-300"
        onClick={() => window.location.href = '/'}>
        Go Home
      </button>
    </div>
  );
};

export default NotFound;
