import React from 'react';

interface SearchInformationProps {
  search: string;
  resultCount: number;
}

const SearchInformation: React.FC<SearchInformationProps> = ({ search, resultCount }) => {
    return (
        <div className="w-full p-4 bg-gray-100 mt-[9rem] flex flex-col items-center">
            <h2 className="text-xl font-bold text-center">Showing results for "{search}"</h2>
            <p className="text-center">{resultCount} results</p>
        </div>
    );
};

export default SearchInformation;

