import React, { FormEvent, ChangeEvent } from 'react';
import { useSearch } from '../contexts/SearchContext';
import FSfakiesmithicon from '../assets/images/FSfakiesmithicon.svg';
import { useNavigate } from 'react-router-dom';

interface TopHeaderWithSearchBarProps {
  basePath: string;
}

const TopHeaderWithSearchBar: React.FC<TopHeaderWithSearchBarProps> = ({ basePath }) => {
  const { searchTerm, setSearchTerm } = useSearch();
  const navigate = useNavigate();

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/store/${basePath}/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchTerm("")
    }
  };
    

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
<div className="w-full h-20 flex items-center justify-center fixed top-0 z-10 bg-white px-4 shadow">
  <div className="flex-1">
  </div>
  <div className="flex justify-center items-center flex-1">
    <a href="https://fakiesmith.com" target="_blank" rel="noopener noreferrer">
      <img 
          src={FSfakiesmithicon} 
          alt="Fakie Smith icon" 
          className="h-24 w-24 object-contain" 
      />
    </a>
  </div>
  <div className="flex-1 flex justify-end">
    <form className="flex items-center bg-gray-200 rounded-full" onSubmit={handleSearchSubmit}>
      <input
        type="text"
        placeholder="Search..."
        className="px-4 py-2 w-full bg-gray-200 rounded-l-full focus:outline-none"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <button
        type="submit"
        className="p-2 bg-purple-800 rounded-full hover:bg-purple-900 focus:outline-none"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </form>
  </div>
</div>


 
    );
};

export default TopHeaderWithSearchBar;

