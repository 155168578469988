import React, {useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'
import StoreHome from '../pages/store/StoreHome';
import StoreAccessories from '../pages/store/StoreAccessories';
import StoreClothes from '../pages/store/StoreClothes';
import StoreAll from '../pages/store/StoreAll';
import StoreDecks from '../pages/store/StoreDecks';
import StoreDecksSearch from '../pages/store/StoreDecksSearch';
import StoreOther from '../pages/store/StoreOther';
import StoreShoes from '../pages/store/StoreShoes';
import StoreTrucks from '../pages/store/StoreTrucks';
import StoreWheels from '../pages/store/StoreWheels';
import NotFound from '../pages/NotFound';
import Landing from '../pages/Landing';
import StoreAccessoriesSearch from '../pages/store/StoreAccessoriesSearch';
import StoreAllSearch from '../pages/store/StoreAllSearch';
import StoreClothesSearch from '../pages/store/StoreClothesSearch';
import StoreOtherSearch from '../pages/store/StoreOtherSearch';
import StoreShoesSearch from '../pages/store/StoreShoesSearch';
import StoreTrucksSearch from '../pages/store/StoreTrucksSearch';
import StoreWheelsSearch from '../pages/store/StoreWheelsSearch';

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search); 
  }, [location]);

  return (
  <Routes>
    <Route path='' element={<Landing />} />
    <Route path="/store" element={<StoreHome />} />
    <Route path="/store/accessories" element={<StoreAccessories />} />
    <Route path="/store/accessories/search" element={<StoreAccessoriesSearch />} />
    <Route path="/store/all" element={<StoreAll />} />
    <Route path="/store/all/search" element={<StoreAllSearch />} />
    <Route path="/store/clothes" element={<StoreClothes />} />
    <Route path="/store/clothes/search" element={<StoreClothesSearch />} />
    <Route path="/store/decks" element={<StoreDecks />} />
    <Route path="/store/decks/search" element={<StoreDecksSearch />} />
    <Route path="/store/other" element={<StoreOther />} />
    <Route path="/store/other/search" element={<StoreOtherSearch />} />
    <Route path="/store/shoes" element={<StoreShoes />} />
    <Route path="/store/shoes/search" element={<StoreShoesSearch />} />
    <Route path="/store/trucks" element={<StoreTrucks />} />
    <Route path="/store/trucks/search" element={<StoreTrucksSearch />} />
    <Route path="/store/wheels" element={<StoreWheels />} />
    <Route path="/store/wheels/search" element={<StoreWheelsSearch />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
  );
};

export default AppRoutes;