import React from 'react';
import TopHeader from '../../components/TopHeader';
import Navbar from '../../components/Navbar';
import { FaInstagram } from 'react-icons/fa';

const StoreHome: React.FC = () => {
  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <TopHeader />
        <Navbar />
        <div className="text-center mt-36">
          <div className="welcome-banner">
            <h1 className="text-6xl font-bold">Welcome!</h1>
          </div>
          <div className="mt-8"> 
            <button 
              className="relative inline-block text-lg font-bold py-2 px-4 rounded overflow-hidden border-2 border-purple-500 group"
              onClick={() => window.location.href = '/store/all'}>
              <span className="absolute top-0 left-0 w-full h-0 transition-all bg-purple-500 group-hover:h-full ease-out duration-300"></span>
              <span className="relative text-purple-500 group-hover:text-white">Shop All</span>
            </button>
          </div>
        </div>
      </div>
      <div className="contact-info text-center pb-4">
        <p>Want to contact? Email me at <a href="mailto:sam@fakiesmith.com" className="text-blue-500 underline">sam@fakiesmith.com</a></p>
      </div>
      <div className="absolute bottom-4 right-4">
        <a href="https://instagram.com/thefakiesmith" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={30} className="text-gray-800 hover:text-gray-600"/>
        </a>
      </div>
    </div>
  );
};

export default StoreHome;

